import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import FocusTrap from 'focus-trap-react'
import onClickOutside from 'react-onclickoutside'

import Styles from './MegaMenu.module.scss'
import { Logo, ItemsRenderer, HamburgerMenu, Link } from '../../index'

const MegaMenu = ({ isMegaMenuActive, toggleMegaMenu, globalMenus, className, hideMegaMenu }) => {
  const primaryNavItems = globalMenus?.header?.primary?.menuItems?.nodes || []
  const secondaryNavItems = globalMenus?.header?.secondary?.menuItems?.nodes || []

  // enable megamenu click outside
  // https:stackoverflow.com/a/41581491/3556531
  MegaMenu.handleClickOutside = () => hideMegaMenu()

  return (
    <FocusTrap
      active={isMegaMenuActive}
      focusTrapOptions={{
        escapeDeactivates: false,
      }}>
      <div>
        <nav
          className={clsx(
            'fixed z-40 right-0 top-0 w-screen h-full md:w-550',
            'p-16 md:p-30 ',
            'flex flex-1 flex-col justify-between ',
            'text-white md:text-right',
            'transform transition duration-700 ease-in-out',
            isMegaMenuActive ? 'translate-x-0' : 'translate-x-full',
            Styles.megamenuBackground, // background
            className
          )}>
          {/* Absolute */}
          <HamburgerMenu
            active={isMegaMenuActive}
            handleOnClick={toggleMegaMenu}
            className='mx-16 md:mx-30'
          />
          {/* Primary Nav Items */}
          <div className={Styles.NavItemsWrapper}>
            <h2 className='sr-only' id='primay-navigation-heading'>
              Primary Navigation
            </h2>
            <div>
              <NavItemsRenderer
                list={secondaryNavItems}
                toggleMegaMenu={toggleMegaMenu}
                ariaLabelledBy='primay-navigation-heading'
                isMegaMenuActive={isMegaMenuActive}
              />
            </div>
          </div>
          {/* Logo & Secondary Nav Items */}
          <div className='flex flex-row-reverse items-end justify-between mb-5 md:mb-0'>
            <h2 className='sr-only' id='secondary-navigation-heading'>
              Secondary Navigation
            </h2>
            <ItemsRenderer
              showActiveDash={true}
              itemClickHandler={toggleMegaMenu}
              list={[]}
              className={clsx(
                'flex flex-col items-end space-y-10 md:space-y-16',
                Styles.textSecondary
              )}
              ariaLabelledBy='secondary-navigation-heading'
            />
            <Logo primary={false} onClick={toggleMegaMenu} tabIndex='-1' />
          </div>
        </nav>
      </div>
    </FocusTrap>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => MegaMenu.handleClickOutside,
}

export default onClickOutside(MegaMenu, clickOutsideConfig)
// export default React.memo(MegaMenu);

/**
 * Render Navigation Items based on supplied list
 */
const NavItemsRenderer = ({ list, toggleMegaMenu, ariaLabelledBy, isMegaMenuActive }) => {
  const router = useRouter()
  const routerPathName = router?.asPath
  const activeLocation = routerPathName === '/' ? '/home' : routerPathName

  return (
    <ul className='space-y-7 md:space-y-10' aria-labelledby={ariaLabelledBy}>
      {list?.map((item, i) => {
        let href = item?.path
        if (href.slice(-1) == '/') {
          href = href.substring(0, href.length - 1)
        }

        href = href === '/home' ? '/' : href
        const isActiveClass = activeLocation === href

        return (
          <li
            key={item?.id}
            style={{ transitionDelay: `${i * 50}ms` }}
            className={clsx(
              'transform transition duration-700 ease-in-out',
              `${i * 50 - { item }}`,
              isMegaMenuActive ? 'translate-x-0' : 'translate-x-full'
            )}>
            <Link href={href}>
              <a
                target={item?.target}
                onClick={isActiveClass ? null : toggleMegaMenu}
                className={clsx(
                  'block',
                  Styles.textPrimary,
                  isActiveClass ? 'pointer-events-none opacity-100' : 'opacity-70'
                )}>
                {item?.label}
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

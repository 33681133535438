import clsx from 'clsx'
import React from 'react'
import { gsap } from 'gsap'
import { useEffect, useState, useContext, useRef } from 'react'

import SocialIcons from '@big-d-companies/social-icons'

import { Icon, Link, Logo, PreviewBar, HamburgerMenu } from '../index'
import { useEventListener } from '../../hooks'
import useIntroEnded from '../../hooks/useIntroEnded'
import useMenus from '../../hooks/useMenus'
import Styles from './Header.module.scss'

const Header = ({
  preview,
  isMegaMenuActive,
  toggleMegaMenu,
  globalMenus,
  additionalHeaderInfo,
}) => {
  if (!process?.browser) return null

  const leftSectionRef = useRef()
  const logoRef = useRef()
  const containerRef = useRef()
  const q = gsap.utils.selector(containerRef)
  const tl = useRef()
  const { introEnded } = useIntroEnded()
  const { setMenus } = useMenus()

  useEffect(() => {
    setMenus(globalMenus)
  }, [globalMenus])

  // Fix SSR warning - inconsistent header div
  // Ref: https://github.com/vercel/next.js/discussions/17443#discussioncomment-637879
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  useEffect(() => {
    if (mounted) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .set(leftSectionRef?.current, { opacity: 0 })
        .set(logoRef?.current, { opacity: 0 })
        .set(q('button'), { opacity: 0, scale: 0 })
    }
  }, [mounted])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 0.5, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .to(logoRef?.current, { opacity: 1 }, 'init+=.2')
        .to(leftSectionRef?.current, { opacity: 1 }, 'init+=.4')
        .to(q('button'), { opacity: 1, scale: 1 }, 'init+=.4')
    }
  }, [introEnded])

  const [isHeaderActive, setHeaderStatus] = useState(true)

  // Dynamic Header Implementation
  // Ref: https://codingreflections.com/hide-header-on-scroll-down/
  const doc = document?.documentElement
  let curScroll
  let prevScroll = window?.scrollY || doc?.scrollTop
  let curDirection = 0
  let prevDirection = 0

  let toggled
  const threshold = 40

  const headerScrollHandler = () => {
    curScroll = window?.scrollY || doc?.scrollTop

    // down
    if (curScroll > prevScroll) curDirection = 2
    else curDirection = 1 // up

    if (curDirection !== prevDirection) toggled = toggleHeader()

    prevScroll = curScroll
    if (toggled) prevDirection = curDirection
  }

  const toggleHeader = () => {
    toggled = true

    if (curDirection === 2 && curScroll > threshold) setHeaderStatus(false)
    else if (curDirection === 1) setHeaderStatus(true)
    else toggled = false

    return toggled
  }

  useEventListener('scroll', headerScrollHandler)

  // Handle Dynamic Left Component on Header for large view
  //  1. Social Icons
  //  2. Back to all Projects
  //  3. Back to all News
  //  4. Back to all Careers

  return (
    mounted && (
      <header
        id='site-header'
        ref={containerRef}
        className={clsx(
          'px-16 py-16 bg-secondary md:px-30 md:py-30',
          'fixed z-20 w-full ',
          'transform duration-300 delay-150 ease-in-out',
          isHeaderActive && 'top-0',
          isHeaderActive && preview && 'top-20',
          !isHeaderActive && '-top-120'
        )}>
        {preview && <PreviewBar />}
        <div className='relative grid grid-cols-3 items-center'>
          {/* Dynamic Left Section */}
          <div className='hidden md:flex md:justify-self-start' ref={leftSectionRef}>
            <DynamicLeftComponent
              additionalHeaderInfo={additionalHeaderInfo}
              globalMenus={globalMenus}
            />
          </div>

          <div className='justify-self-start md:justify-self-center' ref={logoRef}>
            <Logo primary={true} />
          </div>

          {/* Absolute */}
          <HamburgerMenu active={isMegaMenuActive} handleOnClick={toggleMegaMenu} />

          <ul className="hidden md:flex items-center justify-center	uppercase mr-100">
            { globalMenus?.header?.primary?.menuItems?.nodes.map((item, key) => 
              <li className="text-base-A ml-40 whitespace-nowrap" key={key}>
                <Link href={item.path}>
                  <a href={item.path} target={item.target || '_self'}>{item.label}</a>
                </Link>
              </li>
            ) }            
          </ul>
        </div>
      </header>
    )
  )
}

export default React.memo(Header)

const DynamicLeftComponent = ({ additionalHeaderInfo, globalMenus }) => {
  let dynamicLeftComponent
  const socialItems = globalMenus?.socialNetworks?.menuItems?.nodes || []

  switch (additionalHeaderInfo?.pageType) {
    case 'case':
      // Back to all Projects :v2
      const caseHref = `/projects`

      dynamicLeftComponent = (
        <div className={clsx(Styles.button)}>
          <Link href={caseHref}>
            <a className={clsx('flex items-center cursor-pointer')}>
              <Icon className='ml-12' name='arrow' className='transform -rotate-180' />

              <span className='ml-10 text-sm-A -tracking-3 uppercase'>
                {additionalHeaderInfo?.text}
              </span>
            </a>
          </Link>
        </div>
      )
      break

    // Back to all News // Back to all Articles
    case 'newsArticle':
    case 'careerArticle':
      dynamicLeftComponent = (
        <div className={clsx(Styles.button)}>
          <Link href={additionalHeaderInfo?.href}>
            <a className={clsx('flex items-center cursor-pointer')}>
              <Icon className='ml-12' name='arrow' className='transform -rotate-180' />

              <span className='ml-10 text-sm-A -tracking-3 uppercase'>
                {additionalHeaderInfo?.text}
              </span>
            </a>
          </Link>
        </div>
      )
      break

    default:
      dynamicLeftComponent = (
        <SocialIcons
          socialItems={socialItems}
          classNames={{
            link: clsx(
              Styles.socialIconsLink,
              'pointer rounded-full transition duration-100 ease-in-out',
              'w-32 h-32 md:w-38 md:h-38'
            ),
            icon: 'w-full h-14 fill-current object-contain',
          }}
        />
      )
      break
  }

  return dynamicLeftComponent
}
